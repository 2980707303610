_cruxUtils._cruxLocale = {
"crux.no.bc.message":"Keine \u00dcbersicht verf\u00fcgbar. Klicken Sie zum Anzeigen weiterer Details auf den Eintrag.",
"crm.appointments.status.markcompleted1": "Als abgeschlossen markieren",
"crm.mass.actions.all.selected": "<span class=\"dIB vaM\">Alle\u00a0</span><span class='mL3 mR3 crm-font-bold dIB vaM recordCountHt'> {0}\u00a0</span><span class=\"dIB vaM\">{1} dieser Ansicht wurden ausgew\u00e4hlt.</span>",
"crm.module.selectall": "Alle {0} in dieser Ansicht w\u00e4hlen",
"records": "Aufzeichnungen",
"crm.record.not.accessible": "Eintrag/Eintr\u00e4ge nicht abrufbar",
"crm.view.calls.recording.err.msg": "Sprachaufzeichnung verf\u00fcgt \u00fcber keine abspielbare Audio-URL.",
"crm.listview.selected.records": "<span class='mL3 crm-small-font-size crm-font-bold vaM dIB'>{0} </span><span class=\"mL3 seletcedRecords\">\u00a0{1} ausgew\u00e4hlt.</span>",
"totalrecords": "Datens\u00e4tze gesamt",
"crm.listview.options.text.clip": "Text zuschneiden",
"crm.listview.options.text.wrap": "Text umbrechen",
"crm.listview.options.reset.width": "Spaltengr\u00f6\u00dfe zur\u00fccksetzen",
"crm.listview.options.manage": "Spalten verwalten",
"crm.listview.customview.locked": "Spalten k\u00f6nnen nicht angepasst werden, da diese Ansicht gesperrt wurde",
"crm.api.apidashboard.SortBy": "Sortieren nach",
"crm.privacy.listview.consent.locked": "Eintrag wurde gegen Verarbeitung gesperrt",
"crm.label.reschedule.call": "{0} neu planen",
"crm.button.edit": "Bearbeiten",
"Delete": "L\u00f6schen",
"crm.appointments.status.markcancel": "{0} absagen",
"crm.appointments.status.markreschedule": "{0} neu planen",
"crm.label.cancel.call": "{0} stornieren",
"crm.label.mark.completed": "Als abgeschlossen markieren",
"crm.listview.customview.locked": "Spalten k\u00f6nnen nicht angepasst werden, da diese Ansicht gesperrt wurde",
"pf.deleted.state": "Zustand l\u00f6schen",
"crm.project.integration.title": "Zoho Projects",
"crm.integ.sync.message": "Mit {0} synchronisieren",
"crm.schedules.prefix.in": "In",
"crm.record.record.locked.other": "Der Eintrag ist f\u00fcr andere Profilbenutzer gesperrt.",
"crm.record.lock.record.locked": "Eintrag ist gesperrt.",
"crm.approvalProcess.label.waitingForApproval": "Auf Genehmigung wartend",
"crm.reviewprocess.record.review.pending": "Zur Pr\u00fcfung ausstehend",
"crm.approvalProcess.label.waitingForFindAndMerge": "Duplikate werden derzeit zusammengef\u00fchrt.",
"crm.privacy.listview.consent.locked": "Eintrag wurde gegen Verarbeitung gesperrt",
"crm.zia.vision.processing": "Zia validiert die Bilder.",
"crm.zia.vision.rejected.msg": "Eintrag aufgrund Bildvalidierungsfehler abgelehnt.",
"crm.zia.vision.record.failure.msg": "Eintrag wartet aufgrund von Bildvalidierungsfehler auf Genehmigung.",
"crm.alert.max.cvcols.reached": "Sie k\u00f6nnen bis zu {0} Spalten ausw\u00e4hlen.",
"crm.label.small.tags": "Tags",
"crm.label.edit.module": "{0} bearbeiten",
"crm.social.integ.no.result": "Keine Ergebnisse gefunden",
"custommodule.crmfield.fl.name": "{0}-Name",
"Appointment": "Terminvereinbarung",
"crm.button.create.call": "Anruf erstellen",
"crm.module.create": "{0} erstellen",
"crm.customview.activty.badge": "Activity-Badge",
"crm.button.callnow": "Jetzt anrufen",
"crm.manualcalllist.complete.call": "Anruf protokollieren",
"crm.label.schedule.call": "Anruf planen",
"crm.filter.label.replied":"beantwortet",
"bytes": "Byte",
"crm.field.label.user.lookup": "Benutzer",
"crm.translation.refresh.cases": "Die Aktion, die Sie ausf\u00fchren m\u00f6chten, konnte nicht abgeschlossen werden. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut.",
"crm.project.sync.notes.info": "Nur Kommentare zu Aufgaben der verkn\u00fcpften Projekte werden angezeigt",
"crux.note.content.limit.warning.msg":"Im Anmerkungsinhalt d\u00fcrfen nur {0} Zeichen gespeichert werden.",
"crux.note.formatting.options":"Formatierungsoptionen",
"crm.label.required": "Erforderlich",
"crux.condition.not.belongs.to":"geh\u00f6rt nicht zu {0}.",
"crm.project.sync.notes.info": "Nur Kommentare zu Aufgaben der verkn\u00fcpften Projekte werden angezeigt",
"crm.sf.permission.no.edit": "Sie sind nicht zum Aktualisieren von Eintr\u00e4gen berechtigt.",
"crm.sf.permission.no.create": "Sie sind nicht zum Hinzuf\u00fcgen von Eintr\u00e4gen berechtigt.",
"crm.sf.permission.no.delete": "Sie sind nicht zum L\u00f6schen von Eintr\u00e4gen berechtigt.",
"crux.note.this.note":"diese Anmerkung",
"KB": "KB",
"MB": "MB",
"current.logged.in.user.role": "Angemeldeter-Nutzer-Rolle",
"Unit Price": "Einheitspreis",
"Qty in Stock": "Lagerbestand",
"crm.label.Product Code": "Produktcode",
"TotalAfterDiscount": "Gesamt nach Rabatt",
"crm.lookupfilter.entity.errormsg": "{0}-Auswahl erf\u00fcllt nicht die Nachschlagekriterien.",
"crm.iamexception.maxlen": "Sie k\u00f6nnen nur maximal {0}-Zeichen f\u00fcr {1} eingeben",
"crm.record.locking.permission.denied": "Diese Aktion kann nicht durchgef\u00fchrt werden, da der Eintrag gesperrt ist.",
"List Price": "Listenpreis",
"crm.label.no.more.records": "Keine weiteren Eintr\u00e4ge",
"crm.workflow.rule.created.dateAndTime":"{0} am {1} um {2}",
"crux.new.note.msg":"Worum geht es bei dieser Anmerkung?",
"crm.image.uploaded":"Bild hochgeladen.",
"crm.imageupload.drag.drop.here":"Ziehen Sie die Bilder hierher.",
"crm.fileupload.drag.drop.here":"Ziehen Sie die Dateien hierher.",
"crm.fileupload.wait.msg":"Bitte warten Sie ab, bis die ausgew\u00e4hlten Dateien hochgeladen wurden.",
"crm.fileupload.attach.fail":"Anhang fehlgeschlagen.",
"crm.fileupload.support.format":"Unterst\u00fctzte Dateiformate zum Zia-Training: JPG, JPEG, PNG, PDF",
"custmr.prtl.user.role": "Portalbenutzer",
"crm.subform.empty.alert.header":"Leere Zeile kann nicht gespeichert werden",
"crm.crud.lookup.module.inaccessible": "Sie sind nicht zum Zugriff auf dieses Nachschlagemodul berechtigt",
"crm.crud.lookup.inaccessible.record": "Eintrag nicht vorhanden oder nicht von Ihnen abrufbar",
"crm.custombutton.valid.weburl.check": "Bitte geben Sie einen Wert ein.",
"crm.label.subform.goto.top": "Zum Listenanfang",
"crm.label.subform.row.show.all": "Alles anzeigen ({0})",
"cpq.pr.nodiscount": "Kein Diskont verkn\u00fcpft",
"crm.inventory.lineitem.no.pricebook.new": "{0} nicht verkn\u00fcpft.",
"crm.label.subform.allowedlimit": "Limit von {0} Zeilen erreicht.",
"crm.reports.integ.okay": "OK",
"crm.crud.subform.deleted.record": "Aktualisierte Subformularzeilen wurden bereits gel\u00f6scht. Bitte aktualisieren Sie die Seite, versuchen Sie es danach noch einmal.",
"crux.criteria.fieldlabel.valid.check":"Bitte geben Sie eine g\u00fcltige Feldbezeichnung ein",
"crm.subform.delete.confirm.header":"Subformularzeile löschen",
"crm.subform.delete.confirm.msg": "Möchten Sie die ausgewählte Zeile in {0} wirklich löschen?",
"crm.subform.delete.tooltip.msg" :"Die Zeile kann nicht gelöscht werden. Für {0} ist mindestens ein Zeileneintrag erforderlich.",
	"crm.condition.belongs.to":"geh\u00f6rt zu {0}",//no i18n
"crm.condition.not.belongs.to":"geh\u00f6rt nicht zu {0}",//no i18n
"crm.orchestration.error.selectvalue": "Bitte w\u00e4hlen Sie einen Wert",
	"crm.attach.upload.image":"Bilder anh\u00e4ngen",//no i18n
"crm.general.select.photo":"Bild w\u00e4hlen",//no i18n
"crm.imageupload.wait.msg":"Bitte warten Sie, bis die ausgew\u00e4hlten Bilder hochgeladen sind.",//no i18n
"crm.image.filepicker.desc.draganddrop":"Bilder ziehen und ablegen.",//no i18n
"crm.image.filepicker.desc.browse":"Zum Suchen von Bildern klicken...",//no i18n
"crm.attach.upload.userinfo":"Die Gesamtgr\u00f6\u00dfe ist auf <span class",//no i18n
"crm.image.supported.formats":"Unterst\u00fctzte Formate: JPG, PNG, GIF und BMP.",//no i18n
"crm.imageupload.allowed.field.length":"Sie k\u00f6nnen nur maximal {0} Bilder hochladen.",//no i18n
"crm.general.crop.and.set":"Zuschneiden und festlegen",//no i18n
"crm.attach.option.label":"Anh\u00e4ngen",//no i18n
"crm.image.crop.and.rotate":"Zuschneiden und drehen",//no i18n
"crm.image.resolution":"Aufl\u00f6sung",//no i18n
"crm.attachment.size":"Gr\u00f6\u00dfe",//no i18n
"crm.label.close":"Schlie\u00dfen",//no i18n
"crm.label.previous":"Zur\u00fcck",//no i18n
"crm.label.next":"Weiter",//no i18n
"crm.subform.record.create.maxrow.limit": "Maximal {0} Eintr\u00e4ge in {1} zul\u00e4ssig.",
"crm.label.subform.addrows": "Zeile hinzuf\u00fcgen",
"crm.tax.association.check": "Keine Steuern mit diesem Produkt verkn\u00fcpft.",
	"crm.gallery.inventory.template.discount": "Diskont",
	"crm.inventory.discount.scheme.range": "Bereich",
	"Description": "Beschreibung",
	"crm.inventory.lineitem.no.pricebook": "Keine Preisb\u00fccher verkn\u00fcpft.",
	"crm.recordImage.previewimage":"Bildvorschau",//no i18n
"crm.FileuploadField.addNewImage":"Bild hochladen",//no i18n
"crm.fileuploader.removefile":"Entfernen",//no i18n
	"voc.gc.configure":"Konfigurieren",//no i18n
"Edit":"Bearbeiten",//no i18n
	"crm.view.attachment.download":"Download",//no i18n
"crm.label.view":"Anzeigen",//no i18n
	"crm.criteria.secondaryfield.valid.check" : "Bitte geben Sie einen passenden Wert f\u00fcr {0} an.",
	"crm.label.field":"Feld",//no i18n
"crm.label.value":"Wert",//no i18n
	"crm.button.clone":"Klonen",//no i18n
	"sentiment.criteria.wrongcriteria":"Der Kriterienwert darf nicht gr\u00f6\u00dfer als {0} sein",//no i18n
"crm.mb.field.common.splc":"Keine Sonderzeichen zugelassen. Geben Sie einen g\u00fcltigen Wert ein.",//no i18n
	"crm.label.field.plural":"Felder",//no i18n
	"crm.label.in.minutes":"{0} (in Minuten)",//no i18n
	"crm.security.roles.list":"Rollenliste",//no i18n
"crm.security.roles.lookup.info":"Bitte w\u00e4hlen Sie eine Rolle aus der Liste.",//no i18n
"crm.territory.addterritory":"Gebiet hinzuf\u00fcgen",//no i18n
"crm.title.edit.territory":"Gebiet bearbeiten",//no i18n
"crm.territory.title.assign.territories":"Gebiete zuweisen",//no i18n
	"crm.label.context.help":"Hilfe",//no i18n
	"crm.label.from":"Von",//no i18n
"crm.label.to":"Bis",//no i18n
	"workflow.option.webhookFailure.fromDate":"Anfangsdatum",//no i18n
"workflow.option.webhookFailure.toDate":"Enddatum",//no i18n
"crm.custom.field.less.than.equalto":"{0} muss \u2264 {1}sein.",//no i18n
	"crm.wf.usage.date.criteria.error.msg":"Von-Datum kann nicht nach dem Bis-Datum liegen.",//no i18n
	"crm.template.listview.search.no.results":"Keine Ergebnisse gefunden",//No I18n
	"crm.label.tag.new":"Neues Tag",//No I18n
	"crm.label.enter.tag":"Tags eingeben",//No I18n
	"crux.comboBox.max.limit":"Sie k\u00f6nnen nur maximal {0} {1} w\u00e4hlen.",//NO I18n
	"Administrator":"Administrator",//No I18n
	"Standard":"Standard",//No I18n
	"crm.button.add":"Hinzuf\u00fcgen",//NO I18n
	"crm.label.users":"Benutzer", //NO I18n
  "crm.workflow.alert.roles":"Rollen", //NO I18n
  "crm.security.groups":"Gruppen", //NO I18n
	"crm.label.available" : "Verf\u00fcgbar", //NO I18n
	"crm.label.assign.manually" : "Zuweisen", //NO I18n
	"crm.globalsearch.option.all": "Alle", //NO I18n
	"webform.status.Active":"Aktiv", //NO I18n
	"Inactive":"Inaktiv", //NO I18n
  "Confirmed":"Best\u00e4tigt", //NO I18n
  "DeletedUser":"Gel\u00f6scht", //NO I18n
  "crm.user.component.unconfirmed":"Unbest\u00e4tigt",//no i18n
  "crm.feed.group.admin":"Administrator", //NO I18n
  "crm.ln.lable.current":"Aktuelle", //NO I18n
	"crm.label.selected": "Ausgew\u00e4hlte",//NO I18n
	"crm.auditlog.user": "Benutzer", //NO I18n
	"cob.role": "Rolle", //NO I18n
	"zoho.email": "E-Mail", //NO I18n
	"Profile": "Profil", //NO I18n
	"crm.label.search.for.users": "Search Users", //NO I18n
	"crm.security.group.users.empty": "Keine Nutzer gefunden.", //NO I18n
	"crm.label.picklist.none": "Keine", //NO I18n
	"crm.label.notSelected" : "Nicht gew\u00e4hlt",//NO I18n
	"AM" : "AM",//NO I18n
	"Call" : "Anruf",//NO I18n
	"crm.phoneNo.Link.Title" : "\u00dcber Skype anrufen",//NO I18n
	"crm.no.data.found" : "Keine Daten gefunden.",//NO I18n
	"crm.usrpop.non.selected" : "Ausgew\u00e4hlte Nutzer",//NO I18n
	"crm.zti.label.user": "Benutzername", //NO I18n
	"crm.label.no.options.found" : "Keine Optionen gefunden.",//No I18n
	"crm.globalsearch.search.title" : "Suchen",//No I18n
	"None" : "Keine",//No I18n
	"crm.label.criteria.pattern" : "Kriterien-Pattern",//No I18n
	"crm.label.edit.criteria.pattern" : "Muster bearbeiten",//No I18n
	"criteria.error.alert.brackets.count.mismatch" : "Unpassende Anzahl von Klammer im Muster.",//No I18n
	"criteria.error.alert.brackets.invalid" : "Ung\u00fcltige Klammern beim Bedingungsoperator.",//No I18n
	"crm.criteria.number.notmatch.check" : "Bitte pr\u00fcfen Sie das Muster bei {0}.",//No I18n
	"criteria.error.alert.other.params" : "Ung\u00fcltige Inhalte in diesem Muster.",//No I18n
	"criteria.error.alert.andor.rowcount.mismatch" : "Das Kriterienmuster passt nicht zu den von Ihnen gew\u00e4hlten Bedingungen.", //No I18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "Das Kriterienmuster passt nicht zu den von Ihnen gew\u00e4hlten Bedingungen.", //No I18n
	"and" : "und", //No I18n
	"or" : "oder", //No I18n
	"crm.label.or" : "ODER", //No I18n
	"crm.label.and" : "UND", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "Bitte geben Sie eine g\u00fcltige Feldbezeichnung in die {0}-Zeile ein.", //No I18n
	"crm.criteria.condition.valid.check" : "Bitte geben Sie eine g\u00fcltige Bedingung f\u00fcr {0} an.", //No I18n
	"crm.field.valid.check" : "Bitte {0} richtig eingeben.", //No I18n
	"crm.custom.field.less.than.to" : "<i>Von</i>-Bereich darf nicht h\u00f6her als <i>Bis</i>-Bereich sein.", //No I18n
	"crm.alert.label.savepattern" : "Speichern Sie das Muster, bevor Sie Kriterien \u00e4ndern.",//No I18n
	"crm.criteria.max.rowcnt.exceeds" : "Sie k\u00f6nnen nicht zus\u00e4tzliche Kriterien hinzuf\u00fcgen.",//No I18n
	"is" : "ist",//No I18n
	"isn\'t" : "ist nicht",//No I18n
	"contains" : "enth\u00e4lt",//No I18n
	"doesn\'t contain" : "enth\u00e4lt nicht",//No I18n
	"starts with" : "beginnt mit",//No I18n
	"ends with" : "endet mit",//No I18n
	"is empty" : "ist leer",//No I18n
	"is not empty" : "ist nicht leer",//No I18n
	"is before" : "ist vor",//No I18n
	"is after" : "ist nach",//No I18n
	"between" : "zwischen",//No I18n
	"not between" : "nicht inzwischen",//No I18n
	"Today" : "Heute",//No I18n
	"Tommorow" : "Morgen",//No I18n
	"Tommorow Onwards" : "Morgen geh\u2019s los",//No I18n
	"Yesterday" : "Gestern",//No I18n
	"Till Yesterday" : "Bis gestern",//No I18n
	"Last Month" : "Letzten Monat",//No I18n
	"Current Month" : "Aktueller Monat", //No I18n
	"Next Month" : "N\u00e4chster Monat", //No I18n
	"Last Week" : "Letzte Woche", //No I18n
	"Current Week" : "Aktuelle Woche", //No I18n
	"Next Week" : "N\u00e4chste Woche", //No I18n
	"Age in Days" : "Alter in Tagen", //No I18n
	"Due in Days" : "F\u00e4lligkeit in Tagen", //No I18n
	"Scheduled" : "Geplant", //No I18n
	"Attended Dialled" : "Bedient, Gew\u00e4hlt", //No I18n
	"Unattended Dialled" : "Nicht bedient, Gew\u00e4hlt", //No I18n
	"Overdue" : "\u00dcberf\u00e4llig", //No I18n
	"Cancelled" : "Abgesagt", //No I18n
	"Received" : "Empfangen", //No I18n
	"Missed" : "Verpasst", //No I18n
	"crm.alert.character.not.allowed" : "{0} ist nicht zul\u00e4ssig", //No I18n
	"crm.condition.in.last" : "in den letzten", //No I18n
	"crm.zinvoice.dueIn" : "f\u00e4llig in", //No I18n
	"on" : "Am",//No I18n
	"before" : "fr\u00fcher",//No I18n
	"crm.label.general.small.after" : "nach",//No I18n
	"crm.thisweek" : "Diese Woche",//No I18n
	"crm.label.this.month" : "Dieser Monat",//No I18n
	"crm.thisyear" : "Dieses Jahr",//No I18n
	"crm.source.user.and.system" : "Nutzer und System",//No I18n
	"crm.source.user.or.system" : "Nutzer oder System",//No I18n
	"crm.label.system2" : "System",//No I18n
	"crm.source.user.only" : "Nur von Nutzer",//No I18n
	"crm.source.system.only" : "Nur von System",//No I18n
	"crm.condition.till.today" : "Bis heute",//No I18n
	"game.month.left" : "1 Monat",//No I18n
	"game.months.left" : "{0} Monate",//No I18n
	"crm.condition.last.30.days" : "in den letzten 30 Tagen",//No I18n
	"crm.condition.last.60.days" : "in den letzten 60 Tagen",//No I18n
	"crm.condition.last.90.days" : "in den letzten 90 Tagen",//No I18n
	"crm.label.filter.typehere" : "Hier tippen", //No I18N
	"crm.filter.is.not" : "ist nicht", //No I18n
	"crm.condition.until.now" : "Bis jetzt",//No I18n
	"crm.filter.email.isblocked" : "ist blockiert",//No I18n
	"crm.filter.email.isnotblocked" : "ist nicht blockiert",//No I18n
	"crm.label.no.results.match" : "Keine \u00fcbereinstimmenden Ergebnisse",//No I18n
	"crm.label.select.user" : "Klicken Sie zum Ausw\u00e4hlen von Nutzern.", //No I18n
	"current.logged.in.user": "Angemeldeter Nutzer", //NO I18n
	"current.logged.in.user.definition": "Der Nutzer, der die Aufzeichnungsaktion startete.", //NO i18n
	"crm.security.group": "Gruppe", //NO I18n
	"crm.security.role": "Rolle", //NO I18n
	"Date" : "Datum",//No I18n
	"crm.field.valid.decimal.check2" : "Dezimalstellen des <i>{0}</i>-Feldes d\u00fcrfen {1} nicht \u00fcberschreiten.",//No I18n
	"crm.field.empty.check" : "{0} darf nicht leer sein.",//No I18n
	"crm.label.add.note": "Anmerkung hinzuf\u00fcgen", //NO I18n
	"crm.label.simply.by": "bei", //NO I18n
	"crm.general.addnote": "Eine Notiz hinzuf\u00fcgen", //NO I18n
	"crm.general.addtitle": "Einen Titel hinzuf\u00fcgen", //NO I18n
	"crm.label.attach.file": "Dateien anh\u00e4ngen", //NO I18N
	"crm.button.cancel": "Cancel", //NO I18N
	"crm.button.save": "Speichern", //NO I18N
	"crm.button.mass.delete": "L\u00f6schen", //NO I18N
	"crm.warning.delete.record": 'M\u00f6chten Sie \u201e{0}\u201c wirklich in den Papierkorb verschieben?', //NO I18N
	"crm.label.yes": "Ja", //NO I18N
	"crm.note.view.previous": "Vorherige Bemerkungen anzeigen", //NO I18N
  "of": "von", //NO I18N
	"crm.label.notes": "Notizen", //NO I18N
	"crm.note.recent.first": "K\u00fcrzlich, zuerst", //NO I18N
	"crm.note.recent.last": "K\u00fcrzlich, zuletzt", //NO I18N
	"crm.territory.label.only": "Nur {0}", //no i18n
	"crm.select" : "Ausw\u00e4hlen",//No I18n
	"crm.button.apply.filter" : "Filter anwenden",//No I18n
	"crm.alert.maximum.text.values.contains" : "Bei diesem Feld d\u00fcrfen Sie nicht mehr als {0} Werte eingeben.",//No I18n
	"PM" : "PM",//No I18n
	//start-cx create form keys
	"crm.duplicate.value.not.allowed": "Doppelte Werte sind nicht erlaubt.",//no i18n
	"crm.duplicate.value.available": "{0} mit identischem {1} gefunden.",//no i18n
	"crm.duplicate.value.available.multiple": "Mehrere {0} mit identischen {1} gefunden.",//no i18n
	"crm.custombutton.nobuttons.found": "Keine Schaltfl\u00e4chen gefunden",//no i18n
	"crm.custombutton.create.newbutton": "Schaltfl\u00e4che erstellen",//no i18n
	"crm.custombutton.manage.button": "Schaltfl\u00e4chen verwalten",//no i18n
	"crm.custombutton.name": "Name",//no i18n
	"crm.customize.custombutton.function.desc": "Beschreibung",//no i18n
	"crm.custombutton.action": "Schaltfl\u00e4chenaktion",//no i18n
	"crm.custombutton.empty.field.values": "Leerer Wert f\u00fcr folgende Felder gefunden:",//no i18n
	"crm.custombutton.proceed.action": "M\u00f6chten Sie dennoch fortfahren?",//no i18n
	"zb.common.Cancel": "Abbrechen",//no i18n
	"crm.yes.proceed": "Ja, fortfahren.",//no i18n
	"crm.label.module.merge": "{0} vereinigen",//no i18n
	"crm.view.record": "{0} anzeigen",//no i18n
	"crm.tpi.ctiapi.config.choosefile": "Datei w\u00e4hlen",//no i18n
	"crm.label.subform.addrows": "Zeile hinzuf\u00fcgen",//no i18n
	"crm.button.save&new": "Speichern und Neu",//no i18n
	//end-cx create form keys
	"Jan" : "Jan",//No I18n
	"Feb" : "Feb",//No I18n
	"Mar" : "M\u00e4r",//No I18n
	"Apr" : "Apr",//No I18n
	"Jun" : "Jun",//No I18n
	"Jul" : "Jul",//No I18n
	"Aug" : "Aug",//No I18n
	"Sep" : "Sep",//No I18n
	"Oct" : "Okt",//No I18n
	"Nov" : "Nov",//No I18n
	"Dec" : "Dez",//No I18n
	"crm.mb.newversion.msg4" : "OK, verstanden!!", //no i18n
	"crm.label.More" :"Mehr", //no i18n

	"crm.label.unmapped.stages":"Nicht ber\u00fccksichtigt", //no i18n
	"crm.wf.summary.label.ShowInstantActions" : "Mehr anzeigen",//No I18n
	 "crm.wf.summary.label.HideInstantActions" : "Weniger anzeigen",//No I18n

	 	 //filter related keys-start
	"crm.lead.prediction.convert.high":"Hoch",//no i18n
	"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
	"crm.lead.prediction.convert.medium":"Mittel",//no i18n
	"crm.lead.prediction.convert.low":"Niedrig",//no i18n
	"crm.lead.prediction.tooltip.convert":"WAHRSCHEINLICHKEIT",//no i18n
	"crm.lead.prediction.tooltip.score":"PUNKTZAHL",//no i18n
	"Planned":"Geplant",//no i18n
	"Invited":"Eingeladen",//no i18n
	"Sent":"Gesendet",//no i18n
	"Received":"Empfangen",//no i18n
	"Opened":"Ge\u00f6ffnet",//no i18n
	"Responded":"Beantwortet",//no i18n
	"Bounced":"Unzustellbar",//no i18n
	"Opted\ Out":"Ausgestiegen",//no i18n
	"crm.filter.label.with.open":"Mit offenen {0}",//no i18n
	"crm.filter.label.without.open":"Ohne offene {0}",//no i18n
	"crm.filter.label.without.any":"Ohne jegliche {0}",//no i18n
	"crm.filter.label.with.module":"Mit {0}",//no i18n
	"crm.filter.label.activity.due":"{0} f\u00e4llig",//no i18n
	"crm.filter.label.activity.done":"{0} erledigt",//no i18n
	"Notes":"Notizen",//no i18n
	"crm.filter.label.notes.added":"Anmerkungen hinzugef\u00fcgt",//no i18n
	"crm.label.filter.email.status":"Neuester E-Mail-Status",//no i18n
	"crm.label.filter.email.clicked":"geklickt",//no i18n
	"crm.label.filter.email.responded":"beantwortet", //no i18n
	"crm.label.filter.email.info":"Filtern Sie Eintr\u00e4ge basierend auf dem neuesten Status Ihrer gesendeten/empfangenen E-Mails.",//no i18n
	"crm.filter.label.sent":"gesendet",//no i18n
	"crm.filter.label.not.sent":"nicht gesendet",//no i18n
	"crm.filter.label.opened":"ge\u00f6ffnet",//no i18n
	"crm.filter.label.not.opened":"nicht ge\u00f6ffnet",//no i18n
	"crm.filter.label.received":"empfangen",//no i18n
	"crm.filter.label.not.received":"nicht empfangen",//no i18n
	"crm.filter.label.bounced":"abgelehnt",//no i18n
	"crm.filter.label.opened.not.replied":"ge\u00f6ffnet, nicht beantwortet", //no i18n
	"crm.filter.label.any":"Beliebige obige Angaben",//no i18n
	"crm.zia.config.potential.amount":"{0} Betrag",//no i18n
	"Quote\ Stage":"{0}-Stufe",//no i18n
	"crm.module.owner":"{0}: Besitzer",//no i18n
	"Potential\ Closing\ Date":"{0}-Abschlussdatum",//no i18n
	"crm.lead.prediction.likely.convert":"Wahrscheinlich zur Konvertierung",//no i18n
	"crm.predictions.feature.label":"Prognose",//no i18n
	"crm.intelligence.prediction.likelywin":"Gewinn wahrscheinlich",//no i18n
	"crm.intelligence.prediction.likelylose":"Verlust wahrscheinlich",//no i18n
	"crm.intelligence.prediction.halfchance":"50:50-Chance",//no i18n
	"crm.intelligence.prediction.score":"Prognosepunktzahl",//no i18n
	"crm.lead.prediction.recent.score":"Letzte Prognose-Punktzahl",//no i18n
	"crm.intelligence.prediction.lastconv":"Letzte 3 Konversationen",//no i18n
	"crm.intelligence.prediction.recordsfocus":"Eintr\u00e4ge zum Fokussieren",//no i18n
	"crm.intelligence.prediction.slowmoving":"Langsame Bewegung",//no i18n
	"crm.intelligence.prediction.trend.down":"Zuletzt Abw\u00e4rtstrend",//no i18n
	"crm.label.touched.records":"Bearbeitete Eintr\u00e4ge",//no i18n
	"crm.label.untouched.records":"Unbearbeitete Eintr\u00e4ge",//no i18n
	"crm.label.record.action":"Aktion aufzeichnen",//no i18n
	"workflow.rule.view.label.Modified":"Ge\u00e4ndert",//no i18n
	"crm.label.not.modified":"Nicht ver\u00e4ndert",//no i18n
	"crm.label.related.records.action":"Zugeh\u00f6rige-Eintr\u00e4ge-Aktion",//no i18n
	"Done":"Fertig",//no i18n
	"crm.label.not.done":"Nicht ausgef\u00fchrt",//no i18n
	"sentiment.model":"E-Mail-Stimmung",//no i18n
	"sentiment.criteria.count":"Anzahl",//no i18n
	"sentiment.criteria.percentage":"Prozent",//no i18n
	"sentiment.criteria.lastmail":"F\u00fcr die letzte E-Mail",//no i18n
	"Chats":"Chats",//no i18n
	"Attended":"Teilgenommen",//no i18n
	"crm.lead.prediction.popup.text":"Wenn Umwandlung wahrscheinlich {0} ist, sollte die Prognosepunktzahl zwischen {1} liegen.",//no i18n
	"crm.lead.prediction.popup.final":"Bitte \u00e4ndern Sie den Filter entsprechend, versuchen Sie es dann noch einmal.",//no i18n
	"crm.custom.field.less.than.to1":"Anfangsbereich darf nicht gr\u00f6\u00dfer als Zielbereich sein.",//no i18n
	"Last\ Activity\ Date":"Letztes Aktivit\u00e4tsdatum",//no i18n
	"crm.label.vendor.name":"{0}-Name",//no i18n
	"hours":"Stunden",//no i18n
	"days":"Tage",//no i18n
	"weeks":"Wochen",//no i18n
	"months":"Monate",//no i18n
	"years":"Jahre",//no i18n
	"crm.label.general.small.after":"nach",//no i18n
	"Last\ Week":"Letzte Woche",//no i18n
	"Last\ Month":"Letzten Monat",//no i18n
	"crm.module.name":"{0}-Name",//no i18n
	"Campaign":"Kampagne",//no i18n
	"Tasks":"Aufgaben",//no i18n
	"Calls":"Anrufe",//no i18n
	"Events":"Ereignisse",//no i18n
	"sentiment.criteria.wrongcriteria":"Der Kriterienwert darf nicht gr\u00f6\u00dfer als {0} sein",//no i18n
	"crm.chosen.minimum.input.text":"Bitte mindestens  {0} Zeichen eingeben",//no i18n
	"crm.intelligence.prediction.trendup":"Aufw\u00e4rtstrend",//no i18n
	"crm.intelligence.prediction.trenddown":"Abw\u00e4rtstrend",//no i18n
	"crm.zia.prediction.notrend":"No trend",//no i18n
	"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
	"Completed":"Abgeschlossen" ,//no i18n
	"crm.label.success":"Erfolg" ,//no i18n
	"crm.label.Failure":"Fehler" ,//no i18n
	"Both":"Beides" ,//no i18n
	"crm.condition.cannot.empty":"Bedingung darf nicht leer sein.",//no i18n
	"crm.condition.last.30.days":"in den letzten 30 Tagen",//no i18n
	"crm.condition.last.60.days":"in den letzten 60 Tagen",//no i18n
	"crm.condition.last.90.days":"in den letzten 90 Tagen",//no i18n
	"crm.sentiment.Positive":"Positiv",//no i18n
	"crm.sentiment.Negative":"Negativ",//no i18n
	"sentiment.positiveandnegative":"Positiv und negativ",//no i18n
	"sentiment.positiveornegative":"Positiv oder negativ",//no i18n
	"sentiment.positiveonly":"Nur positiv",//no i18n
	"sentiment.negativeonly":"Nur negativ",//no i18n
	"crm.sentiment.Neutral":"Neutral",//no i18n
	"crm.filters.select.campaign.type":"{0}-Typ w\u00e4hlen",//no i18n
	"crm.filters.select.campaign.status":"{0}-Status w\u00e4hlen",//no i18n
	"campaign.Member" : "Mitglied",//no i18n
	"Service":"Service",//no i18n
	"Activities":"Aktivit\u00e4ten",//no i18n
	"crm.livedesk.pot.nextdays":"N\u00e4chste {0} Tage",//no i18n
	"Today\ +\ Overdue":"Heute + \u00dcberf\u00e4llig",//no i18n
	"crm.source.user.and.system":"Nutzer und System",//no i18n
	"crm.source.user.or.system":"Nutzer oder System",//no i18n
	"User":"Benutzer",//no i18n
	"crm.source.user.only":"Nur von Nutzer",//no i18n
	"crm.source.system.only":"Nur von System",//no i18n
	"Scheduled":"Geplant",//no i18n
	"Attended\ Dialled":"Bedient, Gew\u00e4hlt",//no i18n
	"Unattended\ Dialled":"Nicht bedient, Gew\u00e4hlt",//no i18n
	"Cancelled":"Abgesagt",//no i18n
	"crm.filter.email.isblocked":"ist blockiert",//no i18n
	"crm.filter.email.isnotblocked":"ist nicht blockiert",//no i18n
	"condition.till.now":"Bis jetzt",//no i18n
	"crm.recurring.no.months":"{0} Monate",//no i18n
	"crm.lead.prediction.tooltip":"Wahrscheinlich zur Konvertierung - Punktzahl-Bereich",//no i18n
	"crm.website.activity":"Website-Aktivit\u00e4t",//no i18n
	"crm.label.By":"Von",//no i18n
	"crm.chosen.searching.text":"Suche \u2026",//no i18n
	"crm.label.memberstatus.is":", der Mitgliedsstatus lautet",//no i18n
	"crm.events.duration":"Dauer",//no i18n
	"crm.title.clear.name":"L\u00f6schen",//no i18n
	"crm.label.status.is":", der Status ist",//no i18n
	"zia.last3.help":"Konversationen umfassen Anrufe, Aufgaben, {0}, empfangene E-Mails, Anmerkungen, Besuche, Sozialkommentare, Kundendienstanfragen von Desk.",//no i18n
	"crm.label.tag.related.to":"bezieht sich auf",//no i18n

	//filter related keys-End
	"crm.label.account.created" : "Neue(r) {0} wird erstellt.",//No I18n
	"crm.krp.no.records.found" : "Keine {0} gefunden",//No I18n
	"crm.module.new" : "{0} erstellen",//No I18n
	"crm.label.view" : "Anzeigen",//No I18n
	"crm.nsocial.customers" : "Kunden",//No I18n
	"crm.nsocial.open.potential" : "Offene {0}",//No I18n
	"crm.nsocial.lead.contact" : "{0}/{1}",//No i18n
	"Others" : "Sonstige",//No i18n
	"crm.field.length.check" : "{0}-Wert \u00fcberschreitet die Maximall\u00e4nge.", //No I18n
	"crm.lower.now":"jetzt",//no i18n
	"crm.time.min.ago":"{0} Minuten zuvor",//no i18n
	"crm.time.mins.ago":"{0} Minuten zuvor",//no i18n
	"crm.time.hr.ago":"{0} Stunde zuvor",//no i18n
	"crm.time.hrs.ago":"{0} Stunden zuvor", //no i18n
	"AllUsers": "Alle Benutzer", //no i18n
	"crm.label.search":"Suchen",//no i18n
	"crm.api.filterby":"Filtern nach",//no i18n
	"crm.customview.nofields.found":"-- Keine passenden Felder --",//no i18n
	"crm.setup.system.ziarecommendation":"Empfehlung",//no i18n
	"crm.filter.label.all.products":"Alle {0}",//no i18n
	"crm.filter.label.select.products":"Ausgew\u00e4hlte {0}",//no i18n
	"crm.reviewprocess.smart.filter":"Pr\u00fcfungsprozesseintragsstatus",//no i18n
	"crm.dashboard.sharing.empty.value.alert":"Bitte Wert w\u00e4hlen.",//no i18n
	"crm.segmentation.segment.score":"Segmentbewertung",//no i18n
	"crm.filter.label.in":"basierend auf",//no i18n
	"crm.filter.label.and.purchase.in":"und wahrscheinlich zum Kauf in",//no i18n
	"crm.filter.label.last.purchased":"und vor kurzem erworben",//no i18n
	"crm.filter.label.a.day":"ein Tag",//no i18n
	"crm.filter.label.a.week":"eine Woche",//no i18n
	"crm.filter.label.a.month":"ein Monat",//no i18n
	"crm.cal.custom":"Spezifisch",//no i18n
	"crm.mb.field.common.empt":"Der Wert darf nicht leer sein.",//no i18n
	"crm.chosen.error.loading.text":"Hoppla, wir konnten Ihre Ergebnisse nicht laden",//no i18n
	"crm.filter.label.firstbuy":"Erstes Mal",//no i18n
	"crm.filter.label.cwbab":"Abh\u00e4ngig",//no i18n
	"crm.filter.label.fbt":"B\u00fcndel",//no i18n
	"crm.filter.label.rebuy":"Wiederholen",//no i18n
	"crm.filter.label.nextbuy":"Sequenz",//no i18n
	"crm.mxnlookup.select" : "{0} verkn\u00fcpfen",//No I18n
	"crm.lookup.chooserecord":"{0} w\u00e4hlen",//no i18n
	"crm.record.selected":"Ausgew\u00e4hlte {0}",//no i18n
	"crm.module.empty.message" : "Keine {0} gefunden",//No I18n
	"crm.mxnlookup.selected" : "{0} verkn\u00fcpft",//No I18n
	"crm.security.error" : "Ihre Berechtigungen reichen nicht zum Ausf\u00fchren dieser Aktion aus. Wenden Sie sich an Ihren Administrator.", //no i18n
	"crm.label.creator.noPermission"  : "Berechtigung verweigert", //no i18n
	"crm.segmentation.recency" : "Aktualit\u00e4t", //no i18n
	"crm.segmentation.frequency" : "Frequenz",//no i18n
	"crm.segmentation.monetary" : "Geldwert",//no i18n
	"crm.smartfilter.related.module.msg" : "Sie k\u00f6nnen nur maximal drei zugeh\u00f6rige Module w\u00e4hlen.", //no i18n
	"crm.smartfilter.related.module.msg1" : "(Beispiel: E-Mail, Aktivit\u00e4ten, Anmerkungen)", //no i18n
	"crm.smartfilter.related.module.msg2" : "Dauer darf nicht leer sein", //no i18n
	"crm.label.timeZone": "Zeitzone", //NO I18n
	"crm.label.insufficient.privileges": "Unzureichende Berechtigungen zum Ausf\u00fchren dieser Aktion. Wenden Sie sich an Ihren Administrator.", //NO I18n
	"crm.filter.header.secton.system": "Systemdefinierte Filter", //NO I18N
	"crm.filter.header.secton.fields": "Nach Feldern filtern nach Feld", //NO I18N
	"crm.createfield.calcinfo.new" : "Dieses Feld dient zur Berechnung s\u00e4mtlicher Ausdr\u00fccke Ihrer Eingabe.</br> <b>Beispielsweise ergibt 20 + 20</b> automatisch <b>40</b>",//No i18n
	"crm.lable.read.only" : "Schreibgesch\u00fctztes Feld",//No i18n
	"crm.column.sort.asc" : "Aufst",//No I18n
	"crm.column.sort.desc" : "Abst",//No i18n
	"crm.column.unsort" : "Sortierung aufheben",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "Mit Kunde teilen", //NO I18N
	"crm.label.edited":"Bearbeitet",//no i18n
	"crm.label.edited.on":"Bearbeitet am",//no i18n
	"crm.message.limit.exceed": "F\u00fcr {1} sind nur {0} Zeichen erlaubt.", //NO I18N
	"custmr.prtl.notes.shrd.with.custmr": "Mit Kunden geteilt", //NO I18N
	"crm.button.ok" : "OK",  //NO I18N
	"crm.role.already.selected" : "Diese Rolle wurde bereits ausgew\u00e4hlt", //no i18n
	"crm.user.deleted": "NUTZER WURDE GEL\u00d6SCHT",  //NO I18N
	"crm.account.closed": "DIESES KONTO WURDE GESCHLOSSEN",  //NO I18N
	"crm.start.chat": "Chat starten",  //NO I18N
	"crm.start.call": "Anruf starten",  //NO I18N
	"crm.recipient.invalid.email" : "Ung\u00fcltige E-Mails gefunden.", //NO I18N
	"crm.recipient.add.recipient" : "Zus\u00e4tzlichen Empf\u00e4nger hinzuf\u00fcgen", //NO I18N
	"crm.start.video.call": "Videoanruf starten",  //NO I18N //ignorei18n_start

	"Score":"Punktzahl",
	"Positive Score":"Positive Punktzahl",
	"Negative Score":"Negative Punktzahl",
	"Touch Point Score":"Ansatzpunktzahl",
	"Positive Touch Point Score":"Positive Ansatzpunktzahl",
	"Negative Touch Point Score":"Negative Ansatzpunktzahl",
	"crm.label.scoring.rules":"Bewertungsregeln",
	"crm.label.type.minutes": "Minuten hier eingeben", //NO I18N
	"is\ OPEN":"ist OFFEN",//no i18n
	"is\ WON":"ist GEWONNEN",//no i18n
	"is\ LOST":"ist VERLOREN",//no i18n
	"crm.potential.all.open":"Alle offenen Stufen",//no i18n
	"crm.potential.all.won":"Alle geschlossenen gewonnenen Stufen",//no i18n

	"crm.potential.all.lost":"Alle geschlossenen verlorenen Stufen",//no i18n
	"crm.campaign.member.status" : "Mitgliederstatus",//no i18n
	"crm.dashboard.select.type" : "{0} w\u00e4hlen",//no i18n
	"crm.campaign.service.status":"Servicestatus",//no i18n

	"crm.label.addColumn":"Spalte hinzuf\u00fcgen",//no i18n
	"crm.button.clear.filter":"Filter schlie\u00dfen",//no i18n
	"crm.button.show.filter":"Filter anzeigen",//no i18n
	"crm.las.error.user.maxlimit":"Sie k\u00f6nnen nur bis zu 20 Nutzer w\u00e4hlen.",//no i18n
	"crm.las.error.picklist.maxlimit":"Sie k\u00f6nnen nur bis zu 20 Optionen w\u00e4hlen.",//no i18n

	"crm.fileuploader.message.responseerror": "Hochladen fehlgeschlagen", //NO I18N
	"crm.storage.create.error":"Keine neuen Eintr\u00e4ge k\u00f6nnen erstellt werden, da Sie Ihr maximales Datenspeicherlimit erreicht haben.",//no i18n
	"crm.storage.create.error.client":"Keine neue Eintr\u00e4ge k\u00f6nnen erstellt werden, da Ihr Administrator das maximale Datenspeicherlimit erreicht hat. Kontaktieren Sie zum L\u00f6sen des Problems {0}.",//no i18n
	"crm.storage.avail.info":"({0} von {1} verbleibend)",//no i18n
	"crm.storage.error.key.manage":"Datenspeicher verwalten",//no i18n
	"Records":"Datens\u00e4tze",//no i18n
	"crm.workflow.alert.additional.recipients" : "Weitere Empf\u00e4nger", //NO I18N
	"crm.workflow.alert.type.otherEmails" : "Trennen Sie zus\u00e4tzliche E-Mail-Adressen mit Kommas ab.", //NO I18N
	"crm.related.contact.account" : "{0} bezogen auf {1}",//No I18n
	"crm.allcontact.show" : "Alle {0}",//No I18n
	"crm.button.mass.show" : "Zeigen",//No I18n
	"crm.msg.custom.view.not.replied" : "Nicht beantwortete Mitteilungen", //NO I18N
	"crm.msg.custom.view.replied" : "Beantwortete Mitteilungen",//NO I18N
	"crm.workflow.select.recipients" : "Empf\u00e4nger", //NO I18N
	"crm.custom.module.no.profile.selected.alert1":"Bitte w\u00e4hlen Sie mindestens ein Profil.",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "Sie k\u00f6nnen ein Standardprofil nicht l\u00f6schen.",//NO I18N
	"crm.inv.label.add.emails" : "E-Mail-Adressen hinzuf\u00fcgen" ,//NO I18N
	"crm.prediction.analytics.filter.year":"Letztes Jahr",//no i18n
	"Previous\ FY":"Letztes FJ",//no i18n
	"Current\ FY":"Aktuelles FJ",//no i18n
	"Next\ FY":"N\u00e4chstes FJ",//no i18n
	"Current\ FQ":"Aktuelles FQ",//no i18n
	"Next\ FQ":"N\u00e4chstes FQ",//no i18n
	"Previous\ FQ":"Letzte FQ",//no i18n
	"crm.inv.label.add.emails" : "E-Mail-Adressen hinzuf\u00fcgen", //NO I18N
	"crm.picklist.sample.text":"Beispieltext",//no i18n
	"crm.more.colors":"Mehr Farben",//no i18n
	"crm.button.back.alone":"Zur\u00fcck",//no i18n
	"crm.field.label.email":"E-Mail",//no i18n
"crm.zia.nba.feature.label":"N\u00e4chste Bestaktion",//no i18n
"Meeting":"Meeting",//no i18n
"Tomorrow":"Morgen",//no i18n
"crm.gdpr.notavailable.field":"Nicht verf\u00fcgbar",//no i18n
	"crm.setup.system.ziasimilarity":"\u00c4hnlichkeitsempfehlung",//no i18n
	"crm.gdpr.notavailable.field":"Nicht verf\u00fcgbar",//no i18n
	"crm.label.simply.in":"in",//no i18n
	"crm.filter.label.all.products":"Alle {0}",//NO I18N
	'crm.zia.nbx.filter.due' : "Due" ,//NO I18N
	"abm.segment": "Segment", // NO I18N
	"abm.segment.names": "Segmentnamen", // NO I18N
	"abm.abm.segment.name": "ABM-Segmentname", // NO I18N
	"abm.segmentation.techniques": "ABM-Segmentierungstechniken", // NO I18N
	"abm.filters": "ABM-Filter", // NO I18N
	"abm.filters.info": "Diese Filter zeigen Angaben basierend auf {0}, verkn\u00fcpft mit {1}.", // NO I18N
	"abm.rfm.label": "RFM-Bezeichnung", // NO I18N
	"abm.firmographics.label": "Firmografiebezeichnung" ,// NO I18N
	"abm.recommendation.label": "Empfehlungsbezeichnung", // NO I18N
	"abm.voc.label": "VOC-Bezeichnung", // NO I18N
	"abm.engagement.label": "Ansprachebezeichnung", // NO I18N
	"abm.overall.score": "Gesamtpunktzahl", // NO I18N
	"abm.mood.score": "Stimmungspunktzahl", // NO I18N
	"abm.value.score": "Wertpunktzahl", // NO I18N
	"abm.engagement.score": "Ansprachepunktzahl", // NO I18N
	"abm.rfm.score": "RFM-Punktzahl", // NO I18N
	"crm.column.list.max.group.limit" : "Maximallimit erreicht.", //no i18n
	"crm.customview.pin.column":"Spalte anheften",//no i18n
"crm.customview.unpin.column":"Angeheftete Spalte trennen",//no i18n
	"crux.custom.field.greater.than.equalto":"{0} muss ≥ {1}sein.",
	"crux.users.selected.plural" : "{0} Nutzer ausgewählt.",
	"crux.user.selected.singular" :"{0} Nutzer ausgewählt.",
	"crux.criteria.empty.secondaryfield.module" : "Kein passendes {0}-Feld im {1}-Modul gefunden.",
	"crux.criteria.empty.secondaryfield" : "Kein weiteres {0}-Feld zum Vergleich verfügbar. Bitte geben Sie einen Wert zum Vergleich ein.",
	"crux.logged.in.role.definition" : "Die Nutzerrolle, die Aufzeichnungsaktionen startet.",
	"zia.similarity.smartfilter.score":"\u00c4hnlichkeitswert",//no i18n
	"zia.similarity.smartfilter.records":"\u00c4hnlich {0} von",//no i18n
	"zia.similarity.smartfilter.records.search":"\u00c4hnliche {0} anzeigen von",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"Sie k\u00f6nnen nur maximal {0} {1} ausw\u00e4hlen",//no i18n
	"crux.custom.field.greater.than.equalto":"{0} muss ≥ {1}sein.",
	"crux.users.selected.plural" : "{0} Nutzer ausgewählt.",
	"crux.user.selected.singular" :"{0} Nutzer ausgewählt.",
	"crux.criteria.empty.secondaryfield.module" : "Kein passendes {0}-Feld im {1}-Modul gefunden.",
	"crux.criteria.empty.secondaryfield" : "Kein weiteres {0}-Feld zum Vergleich verfügbar. Bitte geben Sie einen Wert zum Vergleich ein.",
	"crux.logged.in.role.definition" : "Die Nutzerrolle, die Aufzeichnungsaktionen startet.",
	"crux.max.limit.unselect" : "Sie können nur bis zu {0} {1} abwählen.", //NO I18N
	"crux.existing.tag" : "\u201e{0}\u201c ist bereits ausgewählt.", //NO I18N
	"crm.label.true" : "Wahr",//NO I18N
	"crm.label.false" : "Falsch",//NO I18N
	"crm.record.locked" : "Gesperrt",//NO I18N
	"crm.filter.number.range":"Werte von {0} \u2013 {1}",//no i18n
	"crm.smartfilter.picklist.options.msg":"Sie k\u00f6nnen diese Option nur f\u00fcr maximal 10 ausgew\u00e4hlte Werte w\u00e4hlen.",//no i18n
	"crm.chosen.maximum.campaigns.selected":"Sie k\u00f6nnen nur maximal 5 {0} w\u00e4hlen",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"Sie k\u00f6nnen nur maximal {0} {1} ausw\u00e4hlen",//no i18n
	"crm.best.time.column.label":"Bester Kontaktzeitpunkt",//no i18n
	"crm.competitoralert.mentioned.in.email":"In <b>E-Mail</b> erw\u00e4hnt",//no i18n
	"crm.competitoralert.with.sentiment":"Mit Stimmung",//no i18n
	"crm.competitoralert.name.error":"Bitte geben Sie einen g\u00fcltigen Wert zum Mitbewerbernamen an.",//no i18n
	"crm.competitoralert.date.error":"Bitte geben Sie ein g\u00fcltiges Datum zur Mitbewerbererw\u00e4hnung in E-Mails an.",//no i18n
	"crm.competitoralert.sentiment.error":"Bitte w\u00e4hlen Sie eine g\u00fcltige Option zur Mitarbeiterstimmung.",//no i18n
	"crm.competitor.name":"Wettbewerber",
"crm.lookup.advance.error.msg" : "Beim Anwenden des Filters ist etwas schiefgelaufen. Aktualisieren Sie die Seite, versuchen Sie es dann noch einmal.",
	"crm.label.add.tags":"Tags hinzuf\u00fcgen",//no i18n
	"crm.confirm.deassociate":"Verkn\u00fcpfung aufheben",//no i18n
	"Contact\ Name" : "{0}-Name",
	"Lead\ Name" : "{0}-Name",
	"-None-" : "Keine",
	"January" : "Januar",
	"February" : "Februar",
	"March" : "M\u00e4rz",
	"April" : "April",
	"May" : "Mai",
	"June" : "Juni",
	"July" : "Juli",
	"August" : "August",
	"September" : "September",
	"October" : "Oktober",
	"November" : "November",
	"December" : "Dezember",
	// Image Upload literals
	"crm.label.browse.files":"Dateien durchsuchen",//no i18n
	"crm.label.lowercase.or":"oder",//no i18n
	"crm.image.header":"Bild",//no i18n
	"crm.image.n.n":"N:N-Verh\u00e4ltnis",//no i18n
	"crm.image.2.2":"2:2-Verh\u00e4ltnis",//no i18n
	"crm.image.4.3":"4:3-Verh\u00e4ltnis",//no i18n
	"crm.image.16.9":"16:9-Verh\u00e4ltnis",//no i18n
	"crm.image.height.width.swap":"H\u00f6he und Breite vertauschen",//no i18n
	"crm.image.rotate.image":"Drehen",//no i18n
	"crm.label.lowercase.of":"von",//no i18n
	"crm.image.empty.name.placeholder" : "Name eingeben",
	"crm.image.crop.and.rotate" : "Zuschneiden und drehen",
	"crm.image.description" : "Beschreibung hinzuf\u00fcgen \u2026",
	"crm.image.actual.size" : "Originalgr\u00f6\u00dfe",
	"crm.image.reset" : "R\u00fccksetzen",
	"crm.image.width.px" : "px",
	"crm.view.attachment.download":"Download",//no i18n
	"crm.label.edit":"Bearbeiten",//no i18n
	"crm.label.close":"Schlie\u00dfen",//no i18n
	"crm.label.any":"Beliebige",
	"crm.image.description":"Beschreibung hinzuf\u00fcgen \u2026",//no i18n
	"crm.image.zoom.in":"Vergr\u00f6\u00dfern",//no i18n
	"crm.image.zoom.out":"Verkleinern",//no i18n
	"crm.label.lowercase.of":"von",//no i18n
	"crm.image.desc.maxsize":"Beschreibung darf 255 Zeichen nicht \u00fcberschreiten.",//no i18n
	"crm.image.name.maxsize":"Der Name darf 100 Zeichen nicht \u00fcberschreiten.",//no i18n
	"crm.fileuploader.message.totalfilesizeexceeded":"Die Dateigesamtgr\u00f6\u00dfe \u00fcberschreitet das zul\u00e4ssige Limit von {0}.",//no i18n
	"crm.fileuploader.message.maxfilesexceeded":"Sie k\u00f6nnen nur maximal {0} Dateien hochladen.",//no i18n
	"crm.fileuploader.message.maxfilesexceeded1":"Sie k\u00f6nnen nur maximal {0} Dateien anh\u00e4ngen.",//no i18n
	"crm.ImageuploadField.size.limit":"Die Gesamtgr\u00f6\u00dfe der Bilder \u00fcbersteigt das erlaubte Limit von {0} MB.",//no i18n
	"crm.attachment.imageUploadField.Minlen.check":"Sie k\u00f6nnen nur 1 Bild w\u00e4hlen",//no i18n
	"crm.image.crop.error":"Bild kann nicht zugeschnitten werden. Bitte versuchen Sie es erneut.",//no i18n
	"crm.attach.here":"hier.",//no i18n
	"crm.image.unsupported.corrupted.single":"Das zum Hochladen vorgesehene Foto ist defekt.",//no i18n
	"Browse":"Durchsuchen",//no i18n
	"crm.imageupload.failure":"Einige Bilder konnten nicht hochgeladen werden. Bitte versuchen Sie es nach etwas Wartezeit noch einmal.",//no i18n
	"zc.editor.width":"Breite",//no i18n
	"ze.editor.height":"H\u00f6he",//no i18n
	"crm.label.delete":"L\u00f6schen",//no i18n
	"crm.image.error.gif":"GIF-Bild kann nicht zugeschnitten werden.",//no i18n
	"crm.fileuploader.message.morefiles":"{0} weitere Dateien",//no i18n
	"crm.fileuploader.message.invalidfileType1":"Die Datei {0} wird nicht unterst\u00fctzt.",//no i18n
	"crm.fileuploader.message.invalidfileType2":"Die Dateien {0} und {1} werden nicht unterst\u00fctzt.",//no i18n
	"crm.attach.upload.sizelimit":"Gesamtgr\u00f6\u00dfenlimit",//no i18n
	"crm.fileuploader.message.maxfilesexceeded" : "Sie k\u00f6nnen nur maximal {0} Dateien hochladen.",
	"crm.file.upload.maxlength.exceeds" : "Sie k\u00f6nnen nur eine Datei in {0} hochladen."

}
